export function convertExcelDateToISODate(value: string | number) {
  return new Date((Number(value) - 25569) * 86400 * 1000).toISOString();
}

export function getTodayDate() {
  return new Date().toISOString().split('T')[0];
}

/**
 * Gets the difference between two dates in days as an integer
 * @param firstDate
 * @param secondDate
 * @returns difference between dates in days
 */
export function dateDifferenceInDays(firstDate: Date, secondDate: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  return Math.ceil((firstDate.getTime() - secondDate.getTime()) / _MS_PER_DAY);
}

/**
 * Parse a string from datetime format to brazilian locale format
 * @param dateTime
 * @returns
 */
export const parseDate = (dateTime: string) => {
  const [yyyy, mm, dd] = dateTime.split('T')[0].split('-');

  return `${dd}/${mm}/${yyyy}`;
};

export function tryParseDate(ts: number | string): Date | null {
  try {
    if (!Date.parse(String(ts)) && !Number.isInteger(ts)) throw 'Invalid Date';
    return new Date(ts);
  } catch {
    return null;
  }
}
